import { API_LIGHTNING_DOCS_CHECK_APP_STATUS, API_LIGHTNING_DOCS_RECORD, API_LIGHTNING_DOCS_RECORD_BY_ID } from 'config'
import Api from 'services/api'

export const createLightningRecord = () => {
  return Api.post(API_LIGHTNING_DOCS_RECORD, {}, {}, { timeout: 150000 })
}

export const deleteLightningRecord = (recordId: string) => {
  return Api.delete(API_LIGHTNING_DOCS_RECORD_BY_ID, {}, { recordId })
}

export const getAllLightningRecord = () => {
  return Api.get(API_LIGHTNING_DOCS_RECORD)
}

export const checkLightningRecordStatus = (recordId: string) => {
  return Api.get(API_LIGHTNING_DOCS_CHECK_APP_STATUS, {}, { recordId })
}
