import cloneDeep from 'clone-deep'
import { InputType, IVisibleProp } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

interface IVisiblePropertyModalProps {
  title?: string
  data: IVisibleProp
  onClose: Function
}

const defaultInputs = (): Record<string, InputType> => ({
  title: {
    inputType: 'text',
    title: 'Title',
    required: false,
    value: true,
  },
  // visible: {
  //   inputType: 'toggle',
  //   title: 'Visible',
  //   required: true,
  //   value: true,
  // },
  tooltip: {
    inputType: 'textarea',
    title: 'Tooltip',
    rows: 3,
    required: false,
    prefix: '',
    value: '',
  },
  visibleLogic: {
    inputType: 'visibleLogic',
    title: '',
    required: false,
    value: [],
  },
})

export const VisiblePropertyModal = ({ data, title, onClose }: IVisiblePropertyModalProps) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  useEffect(() => {
    if (!data) return

    let newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = (data as any)[key]
    })
    // if (newInputs.visible.value === undefined) newInputs.visible.value = true
    if (!data.title) newInputs.title.value = title
    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let newData: any = data || {}
    Object.keys(inputs).map((key) => {
      const input = newInputs[key]
      newInputs[key].error = InputValidate({ ...input, value: input.value })
      if (newInputs[key].error?.length) hasError = true
      newData[key] = newInputs[key].value
    })
    setInputs(newInputs)
    if (hasError) return
    if (newData.title == title || !newData.title) delete newData.title
    onClose(newData)
  }

  return (
    <Modal isOpen title={title} titleOkay="Submit" onClose={() => onClose()} onOk={onSubmit}>
      <div className="w-120">
        {Object.keys(inputs).map((key, index) => {
          return (
            <div key={index} className="mb-2">
              <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
