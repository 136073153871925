import {
  API_FLOOR_ORDER,
  API_LOAN_BORROWER_OUTSTANDING_ITEMS,
  API_LOAN_CLONE_FILE,
  API_LOAN_DELETE,
  API_LOAN_FETCH_FCI_DATA,
  API_LOAN_GET_DUPLICATE_LOANS,
  API_LOAN_GET_GEOLOCATION,
  API_LOAN_GET_ZILLOW_URL,
  API_LOAN_HISTORY,
  API_LOAN_LOG,
  API_LOAN_MOVE_TO_SERVICING,
  API_LOAN_OVERVIEW,
  API_LOAN_OVERVIEW_SECTION,
  API_LOAN_OVERVIEW_SECTION_BY_LOAN_NUMBER,
  API_LOAN_PIPELINE,
  API_LOAN_PROCESS_LOG,
  API_LOAN_PUSH_TO_YIELDEASY,
  API_LOAN_SERVICING_STATUS_CHANGE,
  API_LOAN_SITEXPRO_REPORT,
  API_LOAN_STATISTICS,
  API_LOAN_STATUS_CHANGE,
  API_LOAN_SURVEY,
  API_LOAN_UPDATE_CLOSING_DATE,
  API_LOAN_UPDATE_EMAIL_CONTACTS,
  API_LOAN_UPDATE_FIELDS,
  API_LOAN_VALIDATE_LOAN_NUMBERS,
  API_PIPELINE_BUSINESS_DAYS,
  API_SYNC_NATIONAL_FLOOR,
  pipelineLoanFilterQuery,
} from 'config'
import Api from 'services/api'

export const filterPipeline = (filterQuery: pipelineLoanFilterQuery = {}) => {
  return Api.get(API_LOAN_PIPELINE, filterQuery)
}

export const getLoanOverview = () => {
  return Api.get(API_LOAN_OVERVIEW)
}

export const getduplicateLoans = (loanNumber: string) => {
  return Api.get(API_LOAN_GET_DUPLICATE_LOANS, {}, { loanNumber })
}

export const updateLoanFields = (loanFields: any, isTriggerCondition: boolean = true) => {
  return Api.put(API_LOAN_UPDATE_FIELDS, { loanFields, isTriggerCondition })
}

export const loanMoveToServicing = (loanNumber: number) => {
  return Api.get(API_LOAN_MOVE_TO_SERVICING, {}, { loanNumber })
}

export const loanStatusChange = (status: string, loanNumber: number) => {
  return Api.get(API_LOAN_STATUS_CHANGE, {}, { status, loanNumber })
}

export const servicingStatusChange = (status: string, loanNumber: number) => {
  return Api.get(API_LOAN_SERVICING_STATUS_CHANGE, {}, { status, loanNumber })
}

export const updateEmailContacts = (data: any) => {
  return Api.post(API_LOAN_UPDATE_EMAIL_CONTACTS, data)
}

export const updateClosingDate = (data: any) => {
  return Api.post(API_LOAN_UPDATE_CLOSING_DATE, data)
}

export const getLoanLogs = (key: string) => {
  return Api.get(API_LOAN_LOG, {}, { key })
}

export const getLoanProcessLogs = (key: string, loanNumber: number) => {
  if (loanNumber === undefined) loanNumber = 0
  return Api.get(API_LOAN_PROCESS_LOG, {}, { key, loanNumber })
}

export const updateLoanProcessLog = (key: string, loanNumber: number, data: Record<string, any>) => {
  return Api.put(API_LOAN_PROCESS_LOG, data, { key, loanNumber: loanNumber || 0 })
}

export const getLoanHistory = () => {
  return Api.get(API_LOAN_HISTORY)
}

export const loanOverviewSection = () => {
  return Api.get(API_LOAN_OVERVIEW_SECTION)
}

export const loanOverviewSectionByLoanNumber = (loanNumber: number) => {
  return Api.get(API_LOAN_OVERVIEW_SECTION_BY_LOAN_NUMBER, {}, { loanNumber })
}

export const deleteLoan = (loanNumber: number) => {
  return Api.delete(API_LOAN_DELETE, {}, { loanNumber })
}

export const getLoanStatistics = (
  data: any,
): Promise<{
  byStatus: Array<{ count: string; loanStatus?: string; servicingStatus?: string; sum: string }>
  byLocked?: Array<{ rateLocked: boolean; count: string }>
  brokerUserScore?: string
  quarterScore?: string
  totalCount: number
  closingCount?: number
  closingDays?: any
}> => {
  return Api.post(API_LOAN_STATISTICS, data)
}

export const getPipelineBusDays = () => {
  return Api.get(API_PIPELINE_BUSINESS_DAYS)
}

export const setPipelineBusDays = (values: any[]) => {
  return Api.post(API_PIPELINE_BUSINESS_DAYS, { values })
}

export const loanCloneFile = (data: object) => {
  return Api.post(API_LOAN_CLONE_FILE, data)
}

export const getFloodOrder = () => {
  return Api.get(API_FLOOR_ORDER)
}

export const saveFloodOrderInfo = (data: { key: string; value: any }) => {
  return Api.put(API_FLOOR_ORDER, data)
}

export const submitFloodOrder = (data: Record<string, any>) => {
  return Api.post(API_FLOOR_ORDER, data, {}, { timeout: 150000 })
}

export const syncNationalFlood = () => {
  return Api.post(API_SYNC_NATIONAL_FLOOR)
}

export const pushLoanToYieldeasy = (data: { docKeys: string[]; isForce: boolean }) => {
  return Api.post(API_LOAN_PUSH_TO_YIELDEASY, data)
}

export const validateLoanNumbers = (loanNumbers: number[]): Promise<Record<number, boolean>> => {
  return Api.post(API_LOAN_VALIDATE_LOAN_NUMBERS, loanNumbers)
}

export const getZillowRouteByZipCode = (zipCode: string) => {
  return Api.get(API_LOAN_GET_ZILLOW_URL, {}, { zipCode })
}

export const submitSurvey = (values: Record<string, any>) => {
  return Api.post(API_LOAN_SURVEY, values)
}

export const filterSurvey = (data: Record<string, any>) => {
  return Api.get(API_LOAN_SURVEY, data)
}

export const getSitexProProperty = (data: Record<string, string>) => {
  return Api.get(API_LOAN_SITEXPRO_REPORT, data)
}

export const getSitexProReport = (data: Record<string, string>) => {
  return Api.post(API_LOAN_SITEXPRO_REPORT, data)
}

export const getGeoLocation = () => {
  return Api.get(API_LOAN_GET_GEOLOCATION)
}

export const fetchFciData = () => {
  return Api.get(API_LOAN_FETCH_FCI_DATA, {}, {}, { timeout: 600000 })
}

export const getBorrowerOutstandingItems = () => {
  return Api.get(API_LOAN_BORROWER_OUTSTANDING_ITEMS)
}
