import { AUTH_TOKEN_SET, USER_ATTETION_VIEWED } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { BorrowerAttentionModal } from 'components/Modals/BorrowerAttentionModal'
import { Overview } from 'components/Overview'
import jwtDecode from 'jwt-decode'
import { InvestorAuth } from 'pages/Investor/Auth'
import { TemporaryAuth } from 'pages/Temporary'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { LoanType } from 'reducers/loanDetail.reducer'
import { getBorrowerOutstandingItems } from 'services'
import { BorrowerHeader, Footer, HeaderSimple } from 'stories/layouts'
import { InvestorHeader } from 'stories/layouts/InvestorHeader'
import { setLoanNumber } from 'utils/setLoanNumber'

export const TemporaryLayout = (props: {
  children: any
  noOverview?: boolean
  title?: string
  requireSign?: boolean
  noHeader?: boolean
  backLink?: string
  noShadow?: boolean
}) => {
  const { children, noOverview, requireSign = false, noShadow = false } = props
  const urlParams: any = useParams()
  let { loanNumber, token } = urlParams
  const [isLoading, setLoading] = useState(true)
  const [isLoaded, setLoaded] = useState(false)
  const [isSigned, setSigned] = useState(false)
  const [noHeader, setNoHeader] = useState(false)
  const [noAuth, setNoAuth] = useState(false)
  const [_newToken, setNewToken] = useState('')
  const [loans, setLoans] = useState<Record<string, any>[]>([])
  const [conditions, setConditions] = useState<Record<number, string[]>>({})
  const [signedStatus, setSignedStatus] = useState<Record<number, boolean>>({})
  const [assetStatus, setAssetStatus] = useState<Record<number, boolean>>({})

  const location = useLocation()
  const navigate = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isTemporaryUrl = window.location.href.length > 200

  const isNewSignin = useMemo(() => {
    return [`/borrowerPipelines/signin`, `/investorPipelines/signin`].includes(pathname)
  }, [])

  const hasBackButton = useMemo(() => {
    return pathname.includes('/borrowerPipeline') || urlParams.token == '_'
  }, [pathname, urlParams.token])

  const { registeredToken, loanType, attentionShowed } = useSelector((state: any) => {
    return {
      registeredToken: state.auth.token,
      loanType: state.loanDetail.loanType,
      attentionShowed: state.auth.attentionShowed,
    }
  })

  if (token == '_' && registeredToken) token = undefined

  useEffect(() => {
    if (isNewSignin) {
      setLoaded(true)
      setLoading(false)
      return
    }

    if (token === undefined && registeredToken) {
      const payload: any = jwtDecode(registeredToken)
      if (payload.isSigned) {
        setSigned(true)
        setLoaded(true)
        setLoading(false)
        return
      }
    }

    setLoanNumber()

    if (
      (pathname.includes('investorPipeline') || pathname.includes('borrowerPipeline')) &&
      (!token || token.length < 20)
    ) {
      setLoaded(true)
      setLoading(false)
      return
    }

    if (!token || (!loanNumber && loanNumber !== undefined)) {
      navigate.push('/')
      return
    }
    const payload: any = jwtDecode(token)
    if (!payload || (loanNumber !== undefined && payload.loanNumber != loanNumber)) {
      navigate.push('/')
      return
    }

    if (payload.noHeader) setNoHeader(true)
    if (payload.noAuth) setNoAuth(true)

    if (!requireSign || payload.noAuth) {
      dispatch({
        type: AUTH_TOKEN_SET,
        token,
        user: payload,
      })
      setSigned(true)
    } else {
      if (payload.isSigned) {
        // if (!registeredToken) {
        dispatch({
          type: AUTH_TOKEN_SET,
          token,
          user: payload,
        })
        // }
        setSigned(true)
      } else {
        setSigned(false)
      }
    }
    setLoaded(true)
    setLoading(false)
  }, [])

  const onSigned = async (newToken: string) => {
    const payload: any = jwtDecode(newToken)

    // if (!payload || (loanNumber !== undefined && payload.loanNumber != loanNumber)) {
    //   navigate.push('/')
    //   return
    // }

    dispatch({
      type: AUTH_TOKEN_SET,
      token: newToken,
      user: payload,
    })
    setSigned(true)
    setNewToken(newToken)

    if (pathname.includes('borrowerPipeline')) {
      setLoading(true)
      const { data, conditions, signedStatus, assetStatus } = await getBorrowerOutstandingItems()
      const filteredLoan = data.filter((item: any) => {
        const loanNumber = item.no
        let conditionCleared = true
        conditions[loanNumber]?.map((condition: any) => {
          if (!condition.cleared) conditionCleared = false
        })
        return !(conditionCleared && signedStatus[loanNumber] && assetStatus[loanNumber])
      })
      setLoading(false)
      if (!filteredLoan.length) {
        goToPageAfterSigned(newToken)
        return
      }
      setLoans(filteredLoan)
      setConditions(conditions)
      setSignedStatus(signedStatus)
      setAssetStatus(assetStatus)
    } else {
      goToPageAfterSigned(newToken)
    }
  }

  const goToPageAfterSigned = (_token?: string) => {
    onCloseAttention()
    const { pathname } = location
    let newUrl
    if (pathname.startsWith('/borrowerPipeline') || pathname.startsWith('/investorPipeline'))
      newUrl = `${pathname.replace(`/${token}`, '')}${location.search}`
    else newUrl = `${pathname.replace(token, _token || _newToken)}${location.search}`

    navigate.push(newUrl)
  }

  const onCloseAttention = () => {
    dispatch({
      type: USER_ATTETION_VIEWED,
    })
  }

  if (!isLoaded) return <LayoutLoading show />

  if (!noAuth && requireSign && !isSigned) {
    if (pathname.includes('/investorPipeline'))
      return (
        <div className="Temporary-Layout-container">
          <HeaderSimple />
          <InvestorAuth token={'signin'} onSigned={onSigned} />
          <Footer />
        </div>
      )
    else
      return (
        <div className="Temporary-Layout-container">
          <HeaderSimple />
          <TemporaryAuth token={'signin'} onSigned={onSigned} />
          <Footer />
        </div>
      )
  }

  if (!attentionShowed && loans.length && pathname.includes('/borrowerPipeline')) {
    return (
      <div className="Temporary-Layout-container">
        <HeaderSimple />
        <LayoutLoading show={isLoading} />
        <div className={'home-container sm:text-center lg:text-left w-full block mt-0 relative bg-stone-100 md:py-20'}>
          <div className={'max-w-screen-2xl m-auto lg:pt-10 md:pt-5 sm:pt-2 pb-10 px-2 pt-4'}>
            {!!loans.length && (
              <BorrowerAttentionModal
                loans={loans}
                conditions={conditions}
                signedStatus={signedStatus}
                assetStatus={assetStatus}
                onClose={onCloseAttention}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  return (
    <div className="Temporary-Layout-container">
      {noHeader ? null : isTemporaryUrl ? (
        <HeaderSimple />
      ) : isSigned && requireSign ? (
        pathname.includes('/borrowerPipeline') ? (
          <BorrowerHeader />
        ) : (
          <InvestorHeader />
        )
      ) : (
        loanType === LoanType.DEFAULT && <HeaderSimple />
      )}

      <main className="relative mx-auto main">
        <LayoutLoading show={isLoading} />
        {isLoaded ? (
          noOverview ? (
            <div>{children}</div>
          ) : (
            <div className="px-2 mt-6">
              <Overview
                title={props.title}
                hasBackButton={hasBackButton}
                backLink={props.backLink || `/borrowerPipeline/overview/${loanNumber}`}
                noCompanyTitle={props.title == 'Asset Verification'}
              />
              {noShadow ? (
                children
              ) : (
                <div className="max-w-screen-2xl m-auto mb-4">
                  <div className="relative bg-white shadow1 rounded p-7 min-h-[100px]">{children}</div>
                </div>
              )}
            </div>
          )
        ) : null}
      </main>

      {noHeader
        ? null
        : (loanNumber === undefined || loanType === LoanType.DEFAULT) && (
            <div className="mt-6">
              <Footer />
            </div>
          )}
    </div>
  )
}
