import { LayoutLoading } from 'components/LayoutLoading'
import { LogoBlue } from 'components/Logo'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { unsubscribeEmail } from 'services'
import { Button } from 'stories/components'

export default function Unsubsribe() {
  const history = useHistory()
  const urlParams: any = useParams()
  const { token } = urlParams
  const [isLoading, setLoading] = useState(false)

  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    const payload: Record<string, any> = jwtDecode(token)
    setUserEmail(payload.email)
  }, [])

  const handleUnsubscribe = () => {
    setLoading(true)
    unsubscribeEmail({ email: userEmail, subscribeBroadcastEmail: false, token })
      .then(() => {
        setLoading(false)
        toast(
          'You have successfully unsubscribed from our mailing list. You will no longer receive future email communications from us. If you wish to manage your preferences or resubscribe, you can update your settings on your profile page.',
          { type: 'info' },
        )
        handleGoHome()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleGoHome = () => {
    history.push('/')
  }

  const renderContent = () => {
    return (
      <>
        <div className="max-w-screen-2xl m-auto">
          <div className="flex flex-wrap gap-4 items-center mb-3 px-8">
            <div>
              <LogoBlue />
            </div>
            <h2 className="text-2xl font-bold flex items-center">
              <span className="text-[16px] md:text-[18px] ml-2"></span>
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center min-h-[50vh] text-center space-y-8 px-8">
          <h3 className="text-2xl">Are you sure you want to unsubscribe from future broadcast emails?</h3>
          <p className=" text-gray-700">
            You are about to unsubscribe from our mailing list. If you proceed, you will no longer receive updates.
          </p>
          <div className="flex space-x-4 items-center">
            <Button
              className="text-white px-6 py-3 rounded-lg font-semibold transition duration-200"
              onClick={() => handleUnsubscribe()}
            >
              Unsubscribe
            </Button>
            <a
              href="/"
              className="text-blue-400 font-semibold hover:underline hover:text-blue-800 transition duration-200 underline"
            >
              Go to Home
            </a>
          </div>
        </div>
      </>
    )
  }

  return (
    <TemporaryLayout noOverview={true}>
      <div className="mt-6 relative">
        {renderContent()}
        <LayoutLoading show={isLoading} />
      </div>
    </TemporaryLayout>
  )
}
