import { companyName } from 'config'
import { removeNonPagePermissions } from 'hooks/hasPagePermissions'
import { usePermissions } from 'hooks/usePermissions'
import { DocumentsSlideNameType } from 'pages/Loan/OrderAppraisals/documents'
import { Invoice } from 'pages/Loan/Servicing/Invoice'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getLoanSubmissionDocuments } from 'services'
import type { CategoryDocument } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AccountingCategories } from './AccountingCategories'
import { AccountingFiles } from './AccountingFiles'
import { AccountingRecords } from './AccountingRecords'
import { Accounts } from './Accounts'
import { AccountingDashboard } from './Dashboard'

type menuType = {
  [key: string]: string //fix this
}

export default function ManageAccountings() {
  useTitle(`Manage Accountings - ${companyName}`)

  const navigate = useHistory()
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menus, setMenus] = useState<menuType>({})
  const [documents, setDocuments] = useState<CategoryDocument[] | null>(null)

  const { hasPermission } = usePermissions()

  useEffect(() => {
    let temp: menuType = {}
    if (hasPermission('MANAGE_ACCOUNTING')) {
      temp = {
        accountingDashboard: 'Accounting Dashboard',
        accounts: 'Accounts',
        accountingCategories: 'Accounting Categories',
        accountingFiles: 'Files',
        accountingRecords: 'Profit & Loss',
        accountingBalances: 'Balance Sheet',
        invoices: 'Invoices',
      }
      removeNonPagePermissions(temp, ['manage_accountings'])
    }
    setMenus(temp)
  }, [])

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    navigate.push(`/accountings/${menuItem}`)
  }

  const onOpenDocs = (loanNumber: number) => {
    setDocuments(null)
    setLoanNumber(loanNumber)
    getLoanSubmissionDocuments().then((res) => {
      if (res.success) setDocuments(res.data)
    })
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'accountingDashboard':
        return <AccountingDashboard />

      case 'accounts':
        return <Accounts />

      case 'accountingCategories':
        return <AccountingCategories />

      case 'accountingFiles':
        return <AccountingFiles onOpenDocs={onOpenDocs} />

      case 'accountingRecords':
        return <AccountingRecords type="Record" key="Record" onOpenDocs={onOpenDocs} />

      case 'accountingBalances':
        return <AccountingRecords type="Balance" key="Balance" onOpenDocs={onOpenDocs} />

      case 'invoices':
        return <Invoice isLoan={false} />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  useEffect(() => {
    setMenu(Object.keys(menus)[0])
  }, [menus])

  return (
    <div className="admin-tools-container py-6">
      {documents && <DocumentsSlideNameType documents={documents} loading={false} defaultShow />}
      <div className="admin-tools-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(menus).map((item: string, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setMenu(item)
                  }}
                  className="border-b py-2"
                >
                  <p
                    className={`hover:underline cursor-pointer ${
                      selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                    }`}
                  >
                    {index + 1}. {menus[item as keyof typeof menus]}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
