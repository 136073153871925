import { InputType, loanOptionsConstants, MaritalStatusTypes, OwnershipTypes, YearOptions } from 'config'

import { BorrowerSeperator } from '../CreditScore/constants'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    hasEntityTitle: {
      inputType: 'toggle',
      title: 'Has Entity Name',
    },
    entityTitle: {
      inputType: 'text',
      title: 'Entity Name',
      fontSize: 24,
      required: true,
    },
    entityAddress: {
      inputType: 'map',
      title: 'Entity Address',
      required: true,
    },
    entityTaxID: {
      inputType: 'text',
      title: 'Entity Tax ID',
      type: 'entityTaxID',
      required: true,
      showVerifyTin: true,
    },
    entityState: {
      title: 'State of Entity',
      inputType: 'select',
      hasDefaultOption: true,
      options: [...loanOptionsConstants.states],
      error: '',
      required: true,
    },
    borrowerType: {
      title: 'Borrower Type',
      inputType: 'Select',
      options: loanOptionsConstants.borrowerType,
      error: '',
      required: true,
      hasDefaultOption: true,
    },
    borrowerPosition: {
      title: 'Borrower Position',
      inputType: 'Select',
      options: [
        'General Partner',
        'Limited Partner',
        'Managing Member',
        'Member',
        'President',
        'Vice President',
        'Secretary',
        'Sole Member',
        'Treasurer',
        'Trustee',
      ],
      error: '',
      required: true,
      hasDefaultOption: true,
    },
    ownershipPercentage: {
      inputType: 'text',
      type: 'number',
      maxNumberRange: 100,
      prefix: '%',
      title: 'Ownership Percentage',
      required: true,
    },
    firstName: {
      inputType: 'text',
      title: 'First Name',
      required: true,
    },
    middleName: {
      inputType: 'text',
      title: 'Middle Name',
    },
    lastName: {
      inputType: 'text',
      title: 'Last Name',
      required: true,
    },
    residency: {
      title: 'Citizenship',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.citizenship,
      error: '',
      required: true,
    },
    titleVesting: {
      inputType: 'text',
      title: 'Title Vesting',
      required: true,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      required: true,
    },
    ssn: {
      inputType: 'text',
      type: 'ssn',
      title: 'Social Security Number',
      required: true,
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
      required: true,
    },
    dob: {
      inputType: 'text',
      type: 'date',
      title: 'DOB(MM/DD/YYYY)',
      required: true,
    },
    presentAddress: {
      inputType: 'map',
      title: 'Present Address',
      required: true,
      allowForeign: true,
    },
    mailingAddress: {
      inputType: 'map',
      title: 'Mailing Address',
      required: true,
      allowForeign: true,
    },
    ownership: {
      inputType: 'select',
      title: 'Ownership',
      required: true,
      options: OwnershipTypes,
      hasDefaultOption: true,
    },
    ownedRentedYears: {
      inputType: 'select',
      title: 'Owned/Rented Years',
      required: true,
      options: YearOptions,
      hasDefaultOption: true,
    },
    formerAddress: {
      inputType: 'map',
      title: 'Former Address',
      required: true,
    },
    formerOwnership: {
      inputType: 'select',
      title: 'Former Ownership',
      required: true,
      options: OwnershipTypes,
      hasDefaultOption: true,
    },
    formerOwnedRentedYears: {
      inputType: 'select',
      title: 'Former Address Owned For In Years',
      required: true,
      options: YearOptions,
      hasDefaultOption: true,
    },
    maritalStatus: {
      inputType: 'select',
      title: 'Marital Status',
      required: true,
      options: MaritalStatusTypes,
      hasDefaultOption: true,
    },
  }
}

export const BorrowerSeparatorList = [
  BorrowerSeperator.BORROWER,
  BorrowerSeperator.CO_BORROWER,
  BorrowerSeperator.CO_BORROWER2,
  BorrowerSeperator.CO_BORROWER3,
]
