import cloneDeep from 'clone-deep'
import { CustomFieldType } from 'config/loan.input.fields.constants'
import { FieldOrigin, fieldOriginOptions, IVisibleLogic } from 'config/loan.input.visibility.type'

export interface IBorrowerLoanWorkflowBase {
  title: string
  tooltip?: string
  visibleLogic?: IVisibleLogic[][]
}

export const fieldOriginOptionsExceptCustom = cloneDeep(fieldOriginOptions)
delete (fieldOriginOptionsExceptCustom as any)[FieldOrigin.Custom]

export interface ISelectOption {
  key: string
  mapTo?: string
  title: string
  visible: boolean
  isCustom: boolean
  [key: string]: any
}

export interface IBorrowerLoanWorkflowSelect extends IBorrowerLoanWorkflowInput {
  type: 'select'
  options: ISelectOption[]
  // visibility: Record<string, boolean> // Visibility for each options
}

export interface IBorrowerLoanWorkflowInput extends IBorrowerLoanWorkflowBase {
  fieldOrigin: FieldOrigin
  fieldKey: string
}

export interface IBorrowerLoanWorkflowCustomInput extends IBorrowerLoanWorkflowInput {
  fieldOrigin: FieldOrigin.Custom
  fieldType: CustomFieldType
  linkedFieldOrigin?: FieldOrigin
  linkedFieldKey?: string
}

export interface IBorrowerLoanWorkflowCustomInputText extends IBorrowerLoanWorkflowCustomInput {
  fieldType: CustomFieldType.Text | CustomFieldType.Date | CustomFieldType.Number
}

export interface IBorrowerLoanWorkflowCustomInputSelect extends IBorrowerLoanWorkflowCustomInput {
  fieldType: CustomFieldType.Select
  options: ISelectOption[]
  // visibility: Record<string, boolean> // Visibility for each options
}

export interface IBorrowerLoanWorkflowCustomInputToggle extends IBorrowerLoanWorkflowCustomInput {
  fieldType: CustomFieldType.Toggle
}

export type IBorrowerLoanWorkflowCustomInputs =
  | IBorrowerLoanWorkflowCustomInputText
  | IBorrowerLoanWorkflowCustomInputSelect
  | IBorrowerLoanWorkflowCustomInputToggle

export type IBorrowerLoanWorkflowControl =
  | IBorrowerLoanWorkflowSelect
  | IBorrowerLoanWorkflowInput
  | IBorrowerLoanWorkflowCustomInputs

export interface IBorrowerLoanWorkflowForm extends IBorrowerLoanWorkflowBase {
  type: 'form'
  inputs: IBorrowerLoanWorkflowControl[]
}

export type IBorrowerLoanWorkflowStep = IBorrowerLoanWorkflowSelect | IBorrowerLoanWorkflowForm

export interface IBorrowerLoanWorkflowBaseData {
  manageAccounts: Record<string, string>
}
