import cloneDeep from 'clone-deep'
import { Overview } from 'components/Overview'
import { AccountType } from 'config'
import { removeNonPagePermissions } from 'hooks/hasPagePermissions'
import { usePermissions } from 'hooks/usePermissions'
import { DocumentHistoryModal } from 'pages/LoanSubmission/DocumentHistoryModal'
import { DocumentsSlide } from 'pages/LoanSubmission/documents'
import { LoanSubmissionDocument } from 'pages/LoanSubmission/types'
import { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getLoanSubmission, openS3Document, updateLoanSubmission } from 'services'
import Api from 'services/api'
import { DocumentFile } from 'stories/components'
import { confirm, convertToRoman, formatTime } from 'utils'

import { AggregateEscrow } from './AggregateEscrow'
import { Funding } from './Funding'
import { LoanGenerateDocuments } from './GenerateDocuments'
import { HUD1Page1 } from './HUD1Page1'
import { HUD1Page2 } from './HUD1Page2'
import { HUD1Page3 } from './HUD1Page3'

type menuType = {
  [key: string]: Record<string, any>
}

const _leftMenuItems: menuType = {
  docGen: { title: 'Document Generate' },
  escrow: { title: 'Aggregate Escrow' },
  hud1: { title: 'HUD1', hasChild: true },
  hud1Page1: { title: 'HUD1 Page1', hasParent: true, parent: 'hud1' },
  hud1Page2: { title: 'HUD1 Page2', hasParent: true, parent: 'hud1' },
  hud1Page3: { title: 'HUD1 Page3', hasParent: true, parent: 'hud1' },
  hud1A: { title: 'HUD1A' },
  funding: { title: 'Funding' },
}

export default function ClosingScreen() {
  const { step, profile, loanDetail } = useSelector((state: any) => {
    return {
      step: state.step,
      profile: state.auth.profile,
      loanDetail: state.loanDetail,
    }
  })
  const { hasPermission } = usePermissions()
  const location = useLocation()
  const history = useHistory()
  const loanNumber = Api.getLoanNumber()

  const [selectedMenu, setSelectedMenu] = useState('')
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<LoanSubmissionDocument[]>([])
  const [historyDocument, setHistoryDocument] = useState<Record<string, any> | null>(null)

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    history.replace(`/closing_screen/${loanNumber}?menu=${menuItem}`)
  }

  const brokerProfile = useMemo(() => {
    return !hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')
  }, [])

  const canDeleteDocumentPermanently =
    [AccountType.ADMIN, AccountType.OPERATION_SUPERVISOR, AccountType.UNDERWRITER, AccountType.UW_MANAGER].indexOf(
      profile.accountType,
    ) !== -1

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'docGen':
        return <LoanGenerateDocuments />
      case 'hud1Page1':
        return <HUD1Page1 />
      case 'hud1Page2':
        return <HUD1Page2 />
      case 'hud1Page3':
        return <HUD1Page3 />
      case 'escrow':
        return <AggregateEscrow />
      case 'hud1A':
        return <HUD1Page2 pageType="hud1A" />
      case 'funding':
        return <Funding />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  const hasLoanGenerateDoc = useMemo(
    () => step.overview.submit.detail.submitSetup > 0 && hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION'),
    [step],
  )

  const leftMenuItems = useMemo(() => {
    removeNonPagePermissions(_leftMenuItems as any, ['pipeline', 'closing_screen'])
    if (!hasLoanGenerateDoc) delete _leftMenuItems.docGen
    return _leftMenuItems
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const menuItem = decodeURI(params.get('menu') || '')
    const defaultMenu = Object.keys(leftMenuItems)[0]
    setMenu(menuItem || defaultMenu)
    getLoanSubmission().then(({ documents }) => {
      setDocuments(documents)
      setLoading(false)
    })
  }, [loanNumber])

  const onAddDocuments = async (docs: DocumentFile[]) => {
    const newDocs = cloneDeep(documents)
    const addingDocs: LoanSubmissionDocument[] = docs.map((doc) => ({
      conditionNo: 0,
      name: doc.name,
      key: doc.fileKey,
      status: doc.status,
      category: doc.category,
      createdAt: doc.createdAt,
      generated: false,
      createdBy: profile.email,
    }))
    newDocs.push(...addingDocs)
    setDocuments(newDocs)
    setLoading(true)
    await updateLoanSubmission({}, { update: addingDocs })
    setLoading(false)
  }

  const onUpdateDocuments = async (newDocs: Array<any>, updatedDoc: any) => {
    setDocuments(newDocs)
    setLoading(true)
    await updateLoanSubmission({}, { update: [updatedDoc] })
    setLoading(false)
  }

  const onRemoveDocument = async (document: any, isRemoveCompletely: boolean = false) => {
    const content = (
      <div className="text-gray-900 mb-4 text-md">
        {isRemoveCompletely
          ? 'Are you sure want to delete this document permanently?'
          : 'Are you sure want to delete this document?'}
        <div className="text-gray-800 flex flex-col text-left text-sm">
          {document.conditionNo ? <span>Condition No: {document.conditionNo}</span> : null}
          <span>Document Name: {document.name}</span>
          <span>Uploaded Date: {formatTime(new Date(document.createdAt))}</span>
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(documents)
    const index = newData.findIndex((doc) => doc.conditionNo == document.conditionNo && doc.key == document.key)
    if (index == -1) return
    if (isRemoveCompletely) {
      if (document.generated) return
      const removedDocs = newData.splice(index, 1)
      setDocuments(newData)
      setLoading(true)
      await updateLoanSubmission({}, { delete: removedDocs })
      setLoading(false)
    } else {
      newData[index].conditionNo = 0
      setDocuments(newData)
      setLoading(true)
      await updateLoanSubmission({}, { update: [newData[index]] })
      setLoading(false)
    }
  }

  const onRemoveDocuments = async (docKeys: string[]) => {
    const newData = cloneDeep(documents)
    const indexes = docKeys
      .map((key) => newData.findIndex((doc) => doc.key == key))
      .filter((v) => v != -1)
      .sort((a, b) => a - b)
    const removedDocs: Record<string, any>[] = []
    indexes.reverse().forEach((index) => {
      if (newData[index].generated) return
      const items = newData.splice(index, 1)
      removedDocs.push(...items)
    })
    setLoading(true)
    await updateLoanSubmission({}, { delete: removedDocs })

    setDocuments(newData)
    setLoading(false)
  }

  const onOpenDocument = async (document: any) => {
    openS3Document(document.key, true)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="closingScreen-container px-2 py-6">
        <Overview title="Closing Screen" />
        <div className="closingScreen-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6">
          <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
            <ul className="sidebar-items flex flex-col p-4 pb-20">
              {Object.keys(leftMenuItems)
                .filter((v) => v != 'docGen' || hasLoanGenerateDoc)
                .filter((v) => !leftMenuItems[v].hasParent)
                .map((item: string, index) => {
                  const menuItem = leftMenuItems[item]
                  return (
                    <>
                      <li
                        key={index}
                        onClick={() => {
                          if (!menuItem.hasChild) setMenu(item)
                        }}
                        className="border-b py-2"
                      >
                        <p
                          className={`hover:underline cursor-pointer ${
                            selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                          }`}
                        >
                          {index + 1}. {menuItem.title}
                        </p>
                      </li>
                      {menuItem.hasChild &&
                        Object.keys(leftMenuItems)
                          .filter((v) => leftMenuItems[v].parent == item)
                          .map((childItem: string, childIndex: number) => {
                            return (
                              <li key={childItem} onClick={() => setMenu(childItem)} className="border-b py-2">
                                <p
                                  className={`hover:underline cursor-pointer px-8 ${
                                    selectedMenu === childItem ? 'border px-12 py-1 bg-zinc-100' : 'py-1'
                                  }`}
                                >
                                  {convertToRoman(childIndex + 1)}. {leftMenuItems[childItem].title}
                                </p>
                              </li>
                            )
                          })}
                    </>
                  )
                })}
            </ul>
          </div>
          <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
        </div>
        <DocumentsSlide
          loading={loading}
          setLoading={setLoading}
          documents={documents}
          onAdd={onAddDocuments}
          onUpdate={onUpdateDocuments}
          onRemove={(document: any) => onRemoveDocument(document, true)}
          onSelectedRemove={(selectedKeys: string[]) => onRemoveDocuments(selectedKeys)}
          onOpen={(document: any) => onOpenDocument(document)}
          onOpenDocHistory={setHistoryDocument}
          brokerProfile={brokerProfile}
          canDeleteDocumentPermanently={canDeleteDocumentPermanently}
          submitToLoanSetup={loanDetail.submitToLoanSetup}
        />
        {historyDocument && (
          <DocumentHistoryModal document={historyDocument} onClose={() => setHistoryDocument(null)} />
        )}
      </div>
    </DndProvider>
  )
}
