import { ArrowDownTrayIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { BaseFile } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { openS3Document, uploadFiles } from 'services'
import { getAllTicketMessages, sendTicketMessage } from 'services/apis/ticket'
import { Button, InputFile, TextArea } from 'stories/components'
import { formatTime, isBaseFile, toFileArray } from 'utils'
import { useTitle } from 'utils/pageTitle'

import {
  type ITicketItem,
  ITicket,
  TicketStatusColorMap,
  TicketStatusText,
  TicketTypeStyles,
  TicketTypeTexts,
} from './types'

export const TicketItem = () => {
  const navigate = useHistory()

  const location = useLocation()
  const data: ITicket = (location.state as ITicket) || {}
  useTitle(`${data.title}`)

  const auth = useSelector((state: any) => state.auth)

  const [baseFiles, setBaseFiles] = useState<BaseFile[]>([])

  const [isLoading, setLoading] = useState(false)
  const [messages, setMessages] = useState<ITicketItem[]>([])
  const [msgText, setMsgText] = useState<string>('')

  const [files, setFiles] = useState()

  useEffect(() => {
    if (isLoading) return

    getMsgs(data.id.toString(), 1)
  }, [])

  const getMsgs = (ticketId: string, stackNum: number) => {
    getAllTicketMessages(ticketId, stackNum).then((res) => {
      setMessages(res.tickets)
    })
  }

  const sendTicketMsg = () => {
    if (msgText.length > 0) {
      let msg: Record<string, any> = {
        ticketId: data.id,
        text: `<p>${msgText}</p>`,
        createdBy: auth.profile.name ?? 'User',
        isAdmin: false,
        files: baseFiles,
      }

      sendTicketMessage(msg).then(() => {
        setLoading(false)
        getMsgs(data.id.toString(), 1)
        setMsgText('')
        setBaseFiles([])
        setFiles(undefined)
      })
    } else {
    }
  }

  const handleTicketMsg = (msg: string) => {
    setMsgText(msg)
  }

  const goBackToTickets = () => {
    navigate.goBack()
  }

  const downloadFile = (file: BaseFile) => {
    openS3Document(file.fileKey)
  }

  const onChange = (value: any) => {
    setFiles(value)
    if (value !== null && !isBaseFile(value)) {
      const fileArray = toFileArray(value)
      uploadDocumentFiles(fileArray)
    }
  }

  const uploadDocumentFiles = (files: File[]): Promise<any> => {
    return new Promise((resolve) => {
      const data = {
        path: 'nexys/ticket',
      }
      let acceptedFiles: any = []
      files.map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        console.log('ext', ext)

        acceptedFiles.push(file)
      })
      setLoading(true)

      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        const newDocs: BaseFile[] = []
        const id = Date.now()
        keys.forEach((key, index) => {
          newDocs.push({
            id: id + index,
            name: acceptedFiles[index].name,
            fileKey: key,
            createdAt: Date.now(),
          })
        })
        setBaseFiles(newDocs)
        setLoading(false)
        resolve(true)
      })
    })
  }

  const renderFiles = (files: BaseFile[]) => {
    return (
      <div className="flex flex-wrap gap-2">
        <span className="text-[13px] font-bold">Files:</span>
        {files.map((file) => (
          <span
            className="flex gap-1 items-center hover:underline cursor-pointer text-[15px] font-medium text-shade-blue"
            onClick={() => downloadFile(file)}
          >
            <span className="text-[13px]">{file.name}</span>
            <span>
              <ArrowDownTrayIcon className="w-4 h-4" />
            </span>
          </span>
        ))}
      </div>
    )
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full mb-6">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3 justify-between">
          <h1
            className="text-2xl font-variation-settings-600 flex gap-1 items-center cursor-pointer"
            onClick={goBackToTickets}
          >
            <ArrowLeftIcon className="w-3 h-3" />
            <span className="ml-2 uppercase text-sm">Back</span>
          </h1>
        </div>
        <div className="shadow1 rounded my-8">
          <div className="py-8 border-b px-8 ">
            <div className="md:flex items-center justify-between">
              <h1 className="max-w-1/2 font-semibold text-lg">{data.title}</h1>
              <div className="flex gap-6 items-center mt-4 md:mt-0">
                <p>Created At: {formatTime(data.createdAt)}</p>
                <span className={`${TicketStatusColorMap[data.status]} rounded-sm`}>
                  {TicketStatusText[data.status]}
                </span>
              </div>
            </div>
          </div>
          <div className="py-8 border-b px-8 flex items-center justify-between">
            <div>
              <p className="font-semibold mt-4">
                Ticket Type:{' '}
                <span className={`${TicketTypeStyles[data.type]} font-normal`}>{TicketTypeTexts[data.type]}</span>
              </p>
            </div>
          </div>
          {messages.slice().map((msg, index) => (
            <div key={index}>
              {msg.isAdmin ? (
                <div className="py-8 border-b px-8 border-l-2 border-l-shade-blue bg-gray-100">
                  <div className="flex justify-between items-center">
                    <p className="font-semibold">{msg.createdBy}</p>
                    <p className="font-light text-gray-500 text-sm">{formatTime(msg.createdAt)}</p>
                  </div>
                  <div
                    className="text-xs ck-content !min-h-fit mt-4"
                    dangerouslySetInnerHTML={{ __html: msg.text }}
                  ></div>
                  {msg.files.length > 0 && renderFiles(msg.files)}
                </div>
              ) : (
                <div className="py-8 border-b px-8 border-l-2 border-l-gray-400 bg-gray-100">
                  <div className="flex justify-between items-center">
                    <p className="font-semibold">{msg.createdBy}</p>
                    <p className="font-light text-gray-500 text-sm"> {formatTime(msg.createdAt)}</p>
                  </div>
                  <div
                    className="text-xs ck-content !min-h-fit mt-4"
                    dangerouslySetInnerHTML={{ __html: msg.text }}
                  ></div>
                  {msg.files.length > 0 && renderFiles(msg.files)}
                </div>
              )}
            </div>
          ))}
          <div className="w-full border-t-2 border-t-gray-200 px-8">
            <p className="my-4 text-sm text-gray-900">Message Content</p>
            <div className="">
              <TextArea value={msgText} onChange={(value) => handleTicketMsg(value)} required placeholder="Reply" />
              <div className="mt-2">
                <InputFile
                  multiple={true}
                  onChange={(value) => onChange(value)}
                  value={files}
                  title="Files"
                  acceptFileTypes="application/*,image/*"
                />
              </div>
            </div>
            <div className="flex items-center gap-2 mt-4 pb-6">
              <Button onClick={sendTicketMsg} className="h-fit" size="sm">
                Submit
              </Button>
              <Button
                onClick={() => {
                  goBackToTickets()
                }}
                className="h-fit"
                outline
                size="sm"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
