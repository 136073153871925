import { companyName } from 'config'
import { removeNonPagePermissions } from 'hooks/hasPagePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PaymentOrderType } from 'services'
import { useTitle } from 'utils/pageTitle'

import { BrokerAgreement } from './BrokerAgreement'
import { BrokerTemplates } from './BrokerTemplates'
import { Conditions } from './Conditions'
import { EmailTemplates } from './EmailTemplates'
import { InvestorsServicers } from './InvestorsServicers'
import { LoanApplicationTemplate } from './LoanApplicationTemplate'
import { LoanStructureConditions } from './LoanStructureConditions'
import { LoanTermSheet } from './LoanTermSheet'
import { PaymentTypes } from './PaymentType'
import { ReviewFormItems } from './ReviewFormItems'
import { Rolodex } from './Rolodex'
import { ServicingNotes } from './ServicingNotes'
import SurveyTemplates from './SurveyTemplates'
import { Tasks } from './Tasks'
import { UnderwriterTemplates } from './Templates'
import { WarehouseLenders } from './WarehouseLenders'

type menuType = {
  [key: string]: string //fix this
}

const _leftMenuItems: menuType = {
  conditions: 'Conditions',
  underwriterTemplates: 'UW Condition Templates',
  brokerTemplates: 'Loan Setup Condition Templates',
  loanStructureConditions: 'Loan Structure & Application Conditions',
  tasks: 'Tasks',
  rolodex: 'Rolodex',
  reviewForm: 'Appraisal Review Conditions',
  investorsServicers: 'Investors & Servicers',
  emailTemplates: `Email Templates`,
  servicingNotes: `Servicing Notes`,
  loanTermSheet: `Loan Term Sheet Template`,
  brokerAgreement: 'Broker Agreement Template',
  warehouseLender: 'Warehouse Lenders',
  invoicePaymentTypes: 'Invoice Fee Templates',
  surveyTemplate: 'Review Templates',
  loanApplicationTemplate: 'Loan Application Template',
}

export default function ConditionsAndTemplates() {
  useTitle(`Conditions and Templates - ${companyName}`)

  const navigate = useHistory()
  const [selectedMenu, setSelectedMenu] = useState('')

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    navigate.push(`/condition_template/${menuItem}`)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'conditions':
        return <Conditions />
      case 'underwriterTemplates':
        return <UnderwriterTemplates />
      case 'brokerTemplates':
        return <BrokerTemplates />
      case 'loanStructureConditions':
        return <LoanStructureConditions />
      case 'tasks':
        return <Tasks />
      case 'rolodex':
        return <Rolodex />
      case 'reviewForm':
        return <ReviewFormItems />
      case 'investorsServicers':
        return <InvestorsServicers />
      case 'emailTemplates':
        return <EmailTemplates />
      case 'servicingNotes':
        return <ServicingNotes />
      case 'loanTermSheet':
        return <LoanTermSheet />
      case 'invoicePaymentTypes':
        return <PaymentTypes type={PaymentOrderType.LoanSubmissionRush} />
      case 'surveyTemplate':
        return <SurveyTemplates />
      case 'brokerAgreement':
        return <BrokerAgreement />
      case 'warehouseLender':
        return <WarehouseLenders />
      case 'loanApplicationTemplate':
        return <LoanApplicationTemplate />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  const leftMenuItems = useMemo(() => {
    removeNonPagePermissions(_leftMenuItems as any, ['condition_template'])
    return _leftMenuItems
  }, [])

  useEffect(() => {
    setMenu(Object.keys(leftMenuItems)[0])
  }, [])

  return (
    <div className="vendors-container py-6">
      <div className="vendors-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(leftMenuItems).map((item: string, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setMenu(item)
                  }}
                  className="border-b py-2"
                >
                  <p
                    className={`hover:underline cursor-pointer ${
                      selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                    }`}
                  >
                    {index + 1}. {leftMenuItems[item as keyof typeof leftMenuItems]}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
