import { FieldOrigin, fieldOriginOptions, ILoanFieldPos, IVisibleProp } from 'config/loan.input.visibility.type'
import { defaultInputs as defaultBorrowerInformationInputs } from 'pages/LoanApplication/BorrowerInformation/constants'
import { defaultInputs as defaultDeclarationsHmdaInputs } from 'pages/LoanApplication/DeclarationsHmda/constants'
import { defaultInputs as defaultPropertyInformationInputs } from 'pages/LoanApplication/PropertyInformation/constants'
import { capitalizeFirstLetter } from 'utils'

import { InputType } from './input.type.constants'
import { getInitialLoanFields } from './loan.constants'

export enum CustomFieldType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  Toggle = 'toggle',
}

export const CustomFieldTypeOptions: Record<CustomFieldType, string> = {
  [CustomFieldType.Text]: 'Text',
  [CustomFieldType.Number]: 'Number',
  [CustomFieldType.Date]: 'Date',
  [CustomFieldType.Select]: 'Select',
  [CustomFieldType.Toggle]: 'Toggle',
}

export const defaultOtherInputs = (): Record<string, InputType> => {
  return {
    manageAccounts: {
      title: 'Manage Accounts',
      inputType: 'select',
      hasDefaultOption: true,
      options: {},
      error: '',
      required: true,
      sort: false,
    },
  }
}

export type InputTypeOfLoans = Record<FieldOrigin, Record<string, InputType>>

export const getLoanInputFields = ({
  other = false,
  custom = false,
}: {
  other?: boolean
  custom?: boolean
} = {}): InputTypeOfLoans => {
  const data = {
    [FieldOrigin.LoanStructure]: {
      ...getInitialLoanFields(),
      LTV: {
        title: 'LTV',
        inputType: 'text',
        type: 'number',
        error: '',
        prefix: '%',
      },
    },
    [FieldOrigin.BorrowerInformation]: defaultBorrowerInformationInputs(),
    [FieldOrigin.PropertyInformation]: defaultPropertyInformationInputs(),
    [FieldOrigin.AssetLiability]: {},
    [FieldOrigin.TrackRecord]: {},
    [FieldOrigin.DeclarationsHMDA]: defaultDeclarationsHmdaInputs().inputs,
  } as any
  if (other) data[FieldOrigin.Other] = defaultOtherInputs()
  if (custom) data[FieldOrigin.Custom] = {}

  return data
}

export const getVisibleLogics2Text = (visibleProps: IVisibleProp) => {
  const { title, visibleLogic } = visibleProps
  let newTexts = []
  if (title) newTexts.push(`Title: ${title}`)
  if (visibleLogic) {
    const inputFields = getLoanInputFields()

    const visibleText = visibleLogic
      .map((logic) =>
        logic
          .filter((l) => l.fieldOrigin && l.fieldKey)
          .map((l) => {
            const { fieldOrigin, fieldKey } = l
            const input = inputFields[fieldOrigin][fieldKey]
            const { inputType } = input
            let val = l.value
            if ((inputType == 'select' || inputType == 'Select') && input.options && !Array.isArray(input.options)) {
              val = input.options[val as string]
            }
            return `${fieldOriginOptions[fieldOrigin]} -> ${input.title} = ${val}`
          })
          .join('\n'),
      )
      .filter((v) => !!v)
      .join('\n\n')

    newTexts.push(visibleText)
  }

  return newTexts.join('\n\n')
}

// Filter by inputType
export const getLoanInputFieldOptions = (
  inputType = '',
  { ignoredFields = [] }: { ignoredFields?: ILoanFieldPos[] } = {},
) => {
  const inputFields = getLoanInputFields({ other: true, custom: true })
  const options: Record<FieldOrigin, Record<string, string>> = {} as any
  Object.keys(inputFields).forEach((_fieldOrigin) => {
    const fieldOrigin = _fieldOrigin as any as FieldOrigin
    const inputs = inputFields[fieldOrigin]
    options[fieldOrigin] = {}

    Object.keys(inputs).forEach((key) => {
      const input = inputs[key]
      if (['section', 'Section'].includes(input.inputType)) return
      if (['GoogleMap', 'googleMap'].includes(input.inputType)) return
      const isIgnoredField = ignoredFields.findIndex((v) => v.fieldOrigin == fieldOrigin && v.fieldKey == key) != -1
      if (isIgnoredField) return
      if (inputType && input.inputType.toLowerCase() != inputType.toLowerCase()) return

      if (inputType) options[fieldOrigin][key] = input.title
      else options[fieldOrigin][key] = `${input.title} [${capitalizeFirstLetter(input.inputType)}]`
    })
  })

  return options
}

export const customFieldType2Input = (fieldType: CustomFieldType) => {
  let input: InputType

  switch (fieldType) {
    case CustomFieldType.Text:
    case CustomFieldType.Date:
    case CustomFieldType.Number:
      const types = {
        [CustomFieldType.Text]: 'text',
        [CustomFieldType.Date]: 'date',
        [CustomFieldType.Number]: 'thousandSep',
      }
      input = {
        inputType: 'text',
        type: types[fieldType] as any,
        title: '',
      }
      break
    case CustomFieldType.Toggle:
      input = {
        inputType: 'ToggleButton',
        title: '',
      }
      break
    case CustomFieldType.Select:
      input = {
        inputType: 'select',
        options: [],
        title: '',
        hasDefaultOption: true,
      }
      break
    default:
      input = {
        inputType: 'text',
        title: '',
      }
  }
  input.required = true
  return input
}
