import { GlobalConfigType } from 'actions'
import { FieldOrigin } from 'config'
import { hasPagePermissions, removeNonPagePermissions } from 'hooks/hasPagePermissions'
import { defaultAccessAccountTypes } from 'pages/Admin/AdminTools/Configuration/LoanFieldsProperties'
import { visibilitySections } from 'pages/Admin/AdminTools/Configuration/LoanFieldsProperties/inputs'
import { validateLoanStructureData } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { LoanType } from 'reducers/loanDetail.reducer'
import { hasAuthPermission } from 'utils/hasAuthPermission'

import { BorrowerLabel, BorrowerSeperator } from './CreditScore/constants'

type menuType = {
  [key: string]: string
}

export const getAppBorrowerMenu = (borrowerApplication: boolean = false, customMenuTitles?: Record<string, string>) => {
  const { loan, borrower, globalConfigReducer, auth } = store.getState()
  const accountType = auth.profile.temparary ? 'borrower' : auth.profile.accountType
  let isCorrespondentLoan = loan.type == LoanType.CORRESPONDENT

  let rlt: menuType = {
    borrower: customMenuTitles?.[FieldOrigin.BorrowerInformation] || 'Borrower Information',
    property: customMenuTitles?.[FieldOrigin.PropertyInformation] || 'Property Information',
    asset: customMenuTitles?.[FieldOrigin.AssetLiability] || 'Asset & Liability',
    track: customMenuTitles?.[FieldOrigin.TrackRecord] || 'Track Record',
    hmda: customMenuTitles?.[FieldOrigin.DeclarationsHMDA] || 'Declarations & HMDA',
    sign: customMenuTitles?.['sign'] || 'Acknowledgement & Sign',
    credit: customMenuTitles?.['credit'] || 'Credit Score',
    additionalMember: customMenuTitles?.['additionalMember'] || 'Additional Entity Members',
  }
  if (borrower.borrower?.additionalMembers?.length > 0)
    rlt.additionalMember += ` (${borrower.borrower?.additionalMembers?.length})`
  if (borrowerApplication) delete rlt.credit
  if (isCorrespondentLoan) {
    // delete rlt.sign
    delete rlt.credit
  }

  const visibilityProps = globalConfigReducer[GlobalConfigType.LoanFields]['visibility'] || {}
  const accountTypesProps = globalConfigReducer[GlobalConfigType.LoanFields]['accountTypes'] || {}
  Object.keys(visibilitySections).forEach((fieldOrigin) => {
    if (
      visibilityProps[fieldOrigin] === false ||
      !(accountTypesProps[fieldOrigin] || defaultAccessAccountTypes)[accountType]
    )
      delete rlt[(visibilitySections as any)[fieldOrigin]]
  })
  removeNonPagePermissions(rlt, ['pipeline', 'loanApplication'])
  return rlt
}

export const getAppCoBorrowerMenu = (
  borrowerApplication: boolean = false,
  borrowerSeparator: string = 'coBorrower',
  customMenuTitles?: Record<string, string>,
) => {
  const { loan, globalConfigReducer, auth } = store.getState()
  const accountType = auth.profile.temparary ? 'borrower' : auth.profile.accountType
  let isCorrespondentLoan = loan.type == LoanType.CORRESPONDENT
  let coBorrwerIdx = borrowerSeparator === 'coBorrower' ? 1 : borrowerSeparator === 'coBorrower2' ? 2 : 3
  coBorrwerIdx += 1

  let rlt: menuType = {
    [`borrower${coBorrwerIdx}`]: customMenuTitles?.[FieldOrigin.BorrowerInformation] || 'Borrower Information',
    [`hmda${coBorrwerIdx}`]: customMenuTitles?.[FieldOrigin.DeclarationsHMDA] || 'Declarations & HMDA',
    [`sign${coBorrwerIdx}`]: customMenuTitles?.['sign'] || 'Acknowledgement',
    [`credit${coBorrwerIdx}`]: customMenuTitles?.['credit'] || 'Credit Score',
  }
  if (borrowerApplication) delete rlt[`credit${coBorrwerIdx}`]
  if (isCorrespondentLoan) {
    delete rlt[`credit${coBorrwerIdx}`]
  }

  const visibilityProps = globalConfigReducer[GlobalConfigType.LoanFields]['visibility'] || {}
  const accountTypesProps = globalConfigReducer[GlobalConfigType.LoanFields]['accountTypes'] || {}
  Object.keys(visibilitySections).forEach((fieldOrigin) => {
    if (
      visibilityProps[fieldOrigin] === false ||
      !(accountTypesProps[fieldOrigin] || defaultAccessAccountTypes)[accountType]
    )
      delete rlt[`${(visibilitySections as any)[fieldOrigin]}${coBorrwerIdx}`]
  })
  Object.keys(rlt).forEach((menu) => {
    const menuKey = menu.match(/(\D+)(\d)/)
    if (!menuKey) return
    if (!hasPagePermissions(['pipeline', 'loanApplication', ':menu'], { menu: menuKey[1] })) delete rlt[menu]
  })
  return rlt
}

const getMapKeys = () => {
  const { borrower, globalConfigReducer } = store.getState()
  const customMenuTitles = globalConfigReducer[GlobalConfigType.LoanFields]?.customTitle || {}
  const borrowerMenus = getAppBorrowerMenu(true, customMenuTitles)
  let rtl = { ...borrowerMenus }
  Object.keys(borrower)
    .filter((v) => v !== BorrowerSeperator.BORROWER)
    .map((key) => {
      const coBorrowerMenus = getAppCoBorrowerMenu(false, key, customMenuTitles)
      rtl = { ...rtl, ...coBorrowerMenus }
    })
  return rtl
}

const scoreValidateMenus: any = {
  [BorrowerSeperator.BORROWER]: ['property', 'borrower', 'asset', 'track', 'hmda', 'sign'],
  [BorrowerSeperator.CO_BORROWER]: ['borrower2', 'hmda2', 'sign2'],
  [BorrowerSeperator.CO_BORROWER2]: ['borrower3', 'hmda3', 'sign3'],
  [BorrowerSeperator.CO_BORROWER3]: ['borrower4', 'hmda4', 'sign4'],
}

const signValidateMenus: any = {
  [BorrowerSeperator.BORROWER]: ['property', 'borrower', 'asset', 'track', 'hmda'],
  [BorrowerSeperator.CO_BORROWER]: ['borrower2', 'hmda2'],
  [BorrowerSeperator.CO_BORROWER2]: ['borrower3', 'hmda3'],
  [BorrowerSeperator.CO_BORROWER3]: ['borrower4', 'hmda4'],
}

export const pullScoreValidation = (borrowerSeperator: string): string[] => {
  const { step, auth } = store.getState()
  const noRequireSignValidation = hasAuthPermission(auth, 'ADMIN_TO_AE_PROFILE_PERMISSION')
  const { application } = step
  let rlt: any = []
  const mapKeys = getMapKeys()
  scoreValidateMenus[borrowerSeperator].map((key: string) => {
    if (key == 'asset') return
    if (application[key] === -1) {
      if (['sign', 'sign2'].indexOf(key) !== -1 && noRequireSignValidation) {
      } else {
        let text = ''
        if (borrowerSeperator === 'coBorrower') text = `Co-Borrower's `
        rlt.push(`- ${text} ${mapKeys[key]} Need to be Completed!`)
      }
    }
  })
  return rlt
}

export const signApplictionValidation = () => {
  const { step, borrower, loanDetail, loan } = store.getState()
  const { application } = step
  const { includeCoborrower } = loanDetail
  let borrowerKeys = ['borrower']
  if (includeCoborrower) {
    const coBorrowerKeys = Object.keys(borrower).filter((v) => v !== BorrowerSeperator.BORROWER)
    borrowerKeys.push(...coBorrowerKeys)
  }
  let rlt: any = []
  const mapKeys = getMapKeys()
  const loanStructureErrors = validateLoanStructureData(loan, loanDetail.rateData)
  rlt.push(...loanStructureErrors)
  borrowerKeys.map((borrowerSeperator) => {
    signValidateMenus[borrowerSeperator].map((key: string) => {
      if (key == 'asset') return
      if (application[key] === -1) {
        let text = `${BorrowerLabel[borrowerSeperator]}'s`
        rlt.push(`${text} ${mapKeys[key]} Need to be Completed!`)
      }
    })
  })
  if (![LoanType.CORRESPONDENT, LoanType.TABLEFUNDER].includes(loanDetail.loanType)) {
    if (borrower.borrower.agreeToSign !== true) rlt.push(`Borrower Need to Agree on Acknowledgement!`)
    if (includeCoborrower)
      Object.keys(borrower)
        .filter((v) => v !== BorrowerSeperator.BORROWER)
        .map((borrowerSeperator) => {
          if (borrower[borrowerSeperator].agreeToSign) return
          rlt.push(`${BorrowerLabel[borrowerSeperator]} Need to Agree on Acknowledgement!`)
        })
  }
  return rlt
}

export const stepApplicationKeys: Record<any, any> = {
  [BorrowerSeperator.BORROWER]: {
    borrower: 'borrower',
    property: 'property',
    asset: 'asset',
    hmda: 'hmda',
    sign: 'sign',
    credit: 'credit',
    additionalMember: 'additionalMember',
  },
  [BorrowerSeperator.CO_BORROWER]: {
    borrower: 'borrower2',
    hmda: 'hmda2',
    sign: 'sign2',
    credit: 'credit2',
  },
  [BorrowerSeperator.CO_BORROWER2]: {
    borrower: 'borrower3',
    hmda: 'hmda3',
    sign: 'sign3',
    credit: 'credit3',
  },
  [BorrowerSeperator.CO_BORROWER3]: {
    borrower: 'borrower4',
    hmda: 'hmda4',
    sign: 'sign4',
    credit: 'credit4',
  },
}
