import { BorrowerStatus } from 'pages/Borrower/ManageBorrowers/types'
import Api from 'services/api'

const API_BORROWERS = '/borrowers'
const API_BORROWER_BY_ID = '/borrowers/:id'
const API_BORROWER_RESET_PWD = '/borrowers/resetPwd/:id'
const API_BORROWER_LOAN_REQUEST = '/borrowers/requestLoan'
const API_BORROWER_MY_PROFILE = '/borrowers/myProfile'
const API_BORROWER_QUOTE_INFO = '/borrowers/quoteInfo'
const API_BORROWER_CHANGE_STATUS = '/borrowers/changeStatus/:id'

const API_BORROWER_TRACK = '/borrowers/track/:id'
const API_BORROWER_TRACK_BY_ID = '/borrowers/track/:id/:trackId'

const API_DRAW_REQUEST = '/loan/borrower/drawRequest'
// const API_DRAW_REQUEST_BY_ID = `${API_DRAW_REQUEST}/:id`

export const filterBorrowers = (filters: Record<string, any>) => {
  return Api.get(API_BORROWERS, filters)
}

export const resetBorrowerPassword = (id: number) => {
  return Api.post(API_BORROWER_RESET_PWD, {}, { id })
}

// export const getDrawRequests = () => {
//   return Api.get(API_DRAW_REQUEST)
// }

export const createDrawRequest = (data: Record<string, any>) => {
  return Api.post(API_DRAW_REQUEST, data)
}

// export const updateDrawRequest = (id: number, data: Record<string, any>) => {
//   return Api.put(API_DRAW_REQUEST_BY_ID, data, { id })
// }

// export const deleteDrawRequest = (id: number) => {
//   return Api.delete(API_DRAW_REQUEST_BY_ID, {}, { id })
// }

export const createBorrower = (data: Record<string, any>) => {
  return Api.post(API_BORROWERS, data)
}

export const updateBorrower = (id: number, data: Record<string, any>) => {
  return Api.put(API_BORROWER_BY_ID, data, { id })
}

export const updateBorrowerPassword = (id: number, password: string) => {
  return Api.post(API_BORROWER_BY_ID, { password }, { id })
}

export const getMyBorrowerProfile = () => {
  return Api.get(API_BORROWER_MY_PROFILE)
}

export const updateMyBorrowerProfile = (data: Record<string, any>) => {
  return Api.post(API_BORROWER_MY_PROFILE, data)
}

export const updateMyBorrowerPassword = (password: string) => {
  return Api.put(API_BORROWER_MY_PROFILE, { password })
}

export const borrowerLoanRequest = (data: Record<string, any>) => {
  return Api.post(API_BORROWER_LOAN_REQUEST, data)
}

export const getBorrowerLink = (id: number) => {
  return Api.get(`${API_BORROWER_BY_ID}/link`, {}, { id })
}

export const sendBorrowerEmail = (id: number) => {
  return Api.post(`${API_BORROWER_BY_ID}/email`, {}, { id })
}

export const getBorrowerLoans = (id: number) => {
  return Api.get(`${API_BORROWER_BY_ID}/loans`, {}, { id })
}

export const getBorrowerByNo = (id: number) => {
  return Api.get(`${API_BORROWER_BY_ID}/no`, {}, { id })
}

export const getBorrowerQuoteInfo = () => {
  return Api.get(API_BORROWER_QUOTE_INFO)
}

export const updateBorrowerStatus = (id: number, status: BorrowerStatus) => {
  return Api.post(API_BORROWER_CHANGE_STATUS, { status }, { id })
}

export const deleteBorrower = (id: number) => {
  return Api.delete(API_BORROWER_BY_ID, {}, { id })
}

export const createBorrowerTrack = (id: number, data: Record<string, any>) => {
  return Api.post(API_BORROWER_TRACK, data, { id })
}

export const updateBorrowerTrack = (id: number, trackId: number, data: Record<string, any>) => {
  return Api.put(API_BORROWER_TRACK_BY_ID, data, { id, trackId })
}

export const deleteBorrowerTrack = (id: number, trackId: number) => {
  return Api.delete(API_BORROWER_TRACK_BY_ID, {}, { id, trackId })
}
