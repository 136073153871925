import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import {
  ClosingCostApplyType,
  ClosingCostsMap,
  ClosingCostStatus,
  GFEBlockMap,
  InputType,
  PaidToMap,
  PointsAndFeesMap,
  ResponsiblePartyMap,
  TridBlockMap,
} from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitClosingCostSetup } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { IClosingCostSetup } from './type'

export const closingCostSetupInputs = (): Record<string, InputType> => {
  return {
    lineId: {
      inputType: 'text',
      title: 'Line ID',
      value: '',
      error: '',
      disabled: true,
      key: 'lineId',
      span: '6',
    },
    hudNo: {
      inputType: 'text',
      title: 'HUD No',
      type: 'number',
      value: '',
      error: '',
      required: true,
      key: 'hudNo',
      span: '6',
    },
    hudNoLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'hudNoLocked',
      required: true,
      span: '6',
    },
    lineName: {
      inputType: 'text',
      title: 'Line Name',
      value: '',
      error: '',
      key: 'lineName',
      span: '6',
    },
    lineNameLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'lineNameLocked',
      required: true,
      span: '3',
    },
    lineNameApplyType: {
      title: 'Apply Type',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostApplyType,
      value: '',
      error: '',
      key: 'lineNameApplyType',
      required: true,
      hasDefaultOption: true,
      span: '3',
    },
    lineNameGFE: {
      inputType: 'text',
      title: 'Line Name - GFE',
      value: '',
      error: '',
      key: 'lineName',
      span: '6',
    },
    tridBlock: {
      title: 'TRID Block',
      inputType: 'Select',
      type: 'number',
      options: TridBlockMap,
      value: '',
      error: '',
      key: 'tridBlock',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    tridBlockLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'tridBlockLocked',
      required: true,
      span: '3',
    },
    tridBlockApplyType: {
      title: 'Apply Type',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostApplyType,
      value: '',
      error: '',
      key: 'tridBlockApplyType',
      required: true,
      hasDefaultOption: true,
      span: '3',
    },
    gfeBlock: {
      title: 'GFE Block',
      inputType: 'Select',
      type: 'number',
      options: GFEBlockMap,
      value: '',
      error: '',
      key: 'gfeBlock',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    gfeBlockLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'gfeBlockLocked',
      required: true,
      span: '6',
    },
    type: {
      title: 'Type',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostsMap,
      value: '',
      error: '',
      key: 'type',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    typeLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'typeLocked',
      required: true,
      span: '6',
    },
    paidToType: {
      title: 'Paid to',
      inputType: 'Select',
      type: 'number',
      options: PaidToMap,
      value: '',
      error: '',
      key: 'paidToType',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    paidToTypeLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'paidToTypeLocked',
      required: true,
      span: '6',
    },
    responsiblePartyType: {
      title: 'Responsible Party',
      inputType: 'Select',
      type: 'number',
      options: ResponsiblePartyMap,
      value: '',
      error: '',
      key: 'responsiblePartyType',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    responsiblePartyTypeLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'responsiblePartyTypeLocked',
      required: true,
      span: '6',
    },
    poc: {
      title: 'POC',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'poc',
      required: true,
      span: '6',
    },
    ppfc: {
      title: 'PPFC',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'ppfc',
      required: true,
      span: '6',
    },
    ppfcLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'ppfcLocked',
      required: true,
      span: '6',
    },
    optional: {
      title: 'Optional',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'optional',
      required: true,
      span: '6',
    },
    optionalLocked: {
      title: 'Lock',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'optionalLocked',
      required: true,
      span: '6',
    },
    pointsAndFeesDirective: {
      title: 'Points and Fees Directive',
      inputType: 'Select',
      type: 'number',
      options: PointsAndFeesMap,
      value: '',
      error: '',
      key: 'pointsAndFeesDirective',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    nonRefundable: {
      title: 'Non-Refundable',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'nonRefundable',
      required: true,
      span: '6',
    },
    netFromWire: {
      title: 'Net From Wire',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'netFromWire',
      required: true,
      span: '6',
    },
    glCode: {
      inputType: 'text',
      title: 'GL Code',
      value: '',
      error: '',
      key: 'glCode',
      span: '6',
    },
    status: {
      title: 'Status',
      inputType: 'Select',
      type: 'number',
      options: ClosingCostStatus,
      value: '',
      error: '',
      key: 'status',
      required: true,
      hasDefaultOption: true,
      span: '6',
    },
    notes: {
      inputType: 'textarea',
      value: '',
      error: '',
      key: 'notes',
      rows: 3,
      title: 'Notes',
      span: 'full',
    },
  }
}

export const lineKeys = [
  ['lineId'],
  ['hudNo', 'hudNoLocked'],
  ['lineName', 'lineNameApplyType', 'lineNameLocked'],
  ['lineNameGFE'],
  ['tridBlock', 'tridBlockApplyType', 'tridBlockLocked'],
  ['gfeBlock', 'gfeBlockLocked'],
  ['type', 'typeLocked'],
  ['paidToType', 'paidToTypeLocked'],
  ['responsiblePartyType', 'responsiblePartyTypeLocked'],
  ['poc'],
  ['ppfc', 'ppfcLocked'],
  ['optional', 'optionalLocked'],
  ['pointsAndFeesDirective'],
  ['nonRefundable'],
  ['netFromWire'],
  ['glCode'],
  ['status'],
  ['notes'],
]

export const ClosingCostSetupDetails = (props: {
  defaults: IClosingCostSetup | null
  onBack: Function
  onComplete: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { defaults = null } = props

  useEffect(() => {
    if (!defaults) {
      setInputs(closingCostSetupInputs())
      setLoading(false)
    }

    const newStats = cloneDeep(closingCostSetupInputs()) as Record<string, any>
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
    }
    setInputs(newStats)
  }, [defaults])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      console.log(error)

      if (!disabled && value !== undefined) {
        if (inputs[key].type == 'number') data[key] = removeComma(value)
        else data[key] = value
      }
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    const id = !defaults ? 0 : defaults.id
    const lineId = defaults?.lineId
    if (defaults) {
      delete (defaults as any).id
      delete (defaults as any).lineId
    }
    submitClosingCostSetup(id, data)
      .then(() => {
        if (id == 0) toast('New Closing Cost is added.', { type: 'success' })
        else toast(`Closing Cost ${lineId} is updated.`, { type: 'success' })

        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="ClosingCostSetup-container">
      <LayoutLoading show={isLoading} />
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Closing Cost Setups</p>
        </div>
      </Button>

      {Object.keys(inputs).length &&
        lineKeys.map((line, index) => {
          return (
            <div
              className={`grid md:grid-cols-12 grid-cols-1 gap-4 p-3 ${index % 2 == 0 ? 'bg-slate-100' : ''}`}
              key={index}
            >
              {line.map((key) => {
                const input: InputType = inputs[key]
                const isLock = input.title == 'Lock'
                return (
                  <div
                    className={`input ${input.span ? `md:col-span-${input.span}` : ''} ${
                      isLock && 'md:flex justify-end'
                    }`}
                    key={key}
                  >
                    <RenderInput input={input} Key={key} onChange={onChange} />
                  </div>
                )
              })}
            </div>
          )
        })}
      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{!defaults ? 'Add' : 'Update'}</>
        </Button>
      </div>
    </div>
  )
}
