import { type InputType, loanStatusConstants } from 'config'
import { Appraisal2StatusType } from 'pages/Loan/OrderAppraisals/types'

export interface IMinMaxLimitPerTypes {
  productType: string
  transactionType: string
  minAmount: number
  maxAmount: number
  minPercent: number
  maxPercent: number
}

export interface ILoanGlobalSettings {
  minProposedLoanAmount: number
  maxProposedLoanAmount: number
  minAfterRepairValue: number
  minTotalConstructionFinanced: number
  maxTotalConstructionFinanced: number
  minConstructionFinancedHoldback: number
  maxConstructionFinancedHoldback: number
  maxPercentOfEstimatedClosingAmountToBorrower: number
  minCreditScore: number
  minBrokerFeePercent: number
  maxBrokerFeePercent: number
  minBrokerFeeAmount: number
  commitmentFeeRequiredTypes: IMinMaxLimitPerTypes[]
  dueDilligenceRequiredTypes: IMinMaxLimitPerTypes[]
  defaultUnderwritingFee: number
  rateSheetTime: number
  priceDisplay: string
  appraisalStatisticsVisible: Record<string, boolean>
  showingTermSheetPlaceholders: string[]
  defaultProductName: string
  defaultLockDays: number
  defaultRate: number
  defaultPrice: number
  defaultRateSpread: number
  defaultExitFee: number
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    loanSection: {
      inputType: 'section',
      title: 'Loan',
      span: 'full',
    },
    minProposedLoanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Min Proposed Loan Amount',
      value: '',
      required: true,
    },
    maxProposedLoanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Max Proposed Loan Amount',
      value: '',
      required: true,
    },
    minAfterRepairValue: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Profitability Requirement',
      value: '',
      required: true,
    },
    minTotalConstructionFinanced: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Min Total Construction Financed',
      value: '',
      required: true,
    },
    maxTotalConstructionFinanced: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Max Total Construction Financed',
      value: '',
      required: true,
    },
    minConstructionFinancedHoldback: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Min Construction Financed Holdback',
      value: '',
      required: true,
    },
    maxConstructionFinancedHoldback: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Max Construction Financed Holdback',
      value: '',
      required: true,
    },
    maxPercentOfEstimatedClosingAmountToBorrower: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Max Percent of Estimated Closing Amount to Borrower',
      value: '',
      required: true,
    },
    minCreditScore: {
      inputType: 'text',
      type: 'number',
      title: 'Min Credit Score',
      value: '',
      required: true,
    },
    minBrokerFeeAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Min Broker Fee Amount',
      value: '',
      prefix: '$',
      required: true,
    },
    commitmentFeeRequiredTypes: {
      inputType: 'text',
      type: 'text',
      title: 'Min/Max Commitment Fee Amount',
      value: '',
      readOnly: true,
    },
    dueDilligenceRequiredTypes: {
      inputType: 'text',
      type: 'text',
      title: 'Min/Max Due Dilligence Amount',
      value: '',
      readOnly: true,
    },
    minBrokerFeePercent: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Min Broker Fee Percent',
      value: '',
      required: true,
    },
    maxBrokerFeePercent: {
      inputType: 'text',
      type: 'number',
      title: 'Max Broker Fee Percent',
      value: '',
      prefix: '%',
      required: true,
    },
    defaultUnderwritingFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Default Underwriting Fee',
      value: '',
      prefix: '$',
      required: true,
    },
    rateSheetTime: {
      inputType: 'text',
      type: 'number',
      title: 'Rate Sheet Time',
      value: '',
      prefix: 's',
      required: true,
    },
    priceDisplay: {
      inputType: 'select',
      title: 'Price Display',
      options: ['Price', 'Points'],
      hasDefaultOption: true,
      required: true,
    },
    manualPricingSection: {
      inputType: 'section',
      title: 'Manual Pricing',
      span: 'full',
    },
    defaultProductName: {
      inputType: 'text',
      type: 'text',
      title: 'Default Product Name',
      value: '',
      required: true,
    },
    defaultLockDays: {
      inputType: 'text',
      type: 'number',
      title: 'Default Lock Days',
      value: '',
      required: true,
    },
    defaultRate: {
      inputType: 'text',
      type: 'number',
      title: 'Default Rate',
      value: '',
      required: true,
    },
    defaultPrice: {
      inputType: 'text',
      type: 'number',
      title: 'Default Price',
      value: '',
      required: true,
    },
    defaultRateSpread: {
      inputType: 'text',
      type: 'number',
      title: 'Default Rate Spread',
      value: '',
      required: true,
    },
    defaultExitFee: {
      inputType: 'text',
      type: 'number',
      title: 'Default Exit Fee',
      value: '',
      required: true,
    },
    appraisalSection: {
      inputType: 'section',
      title: 'Appraisal',
      span: 'full',
    },
    appraisalStatisticsVisible: {
      inputType: 'multiselect',
      title: 'Visible Appraisal Status',
      options: Appraisal2StatusType,
      defaultSelected: false,
      value: {},
      required: true,
    },
  }
}

export const deafultInputsV2 = (loanGlobalStatus: any): Record<string, InputType> => {
  return {
    pipelineSection: {
      inputType: 'section',
      title: 'Pipeline',
      span: 'full',
    },
    nonServicingLoanStatus: {
      inputType: 'multiselect',
      title: 'Non Servicing Default Status',
      options: loanGlobalStatus,
    },
    servicingLoanStatus: {
      inputType: 'multiselect',
      title: 'Servicing Default Status',
      options: loanStatusConstants.servicingStatus,
    },
    borrowerLoanStatus: {
      inputType: 'multiselect',
      title: 'Borrower Default Status',
      options: loanGlobalStatus,
    },

    manageAccountSection: {
      inputType: 'section',
      title: 'Manage Accounts',
      span: 'full',
    },
    activeLoanStatusForAEDisable: {
      inputType: 'multiselect',
      title: 'Once AE Disabled, Switch AE on loan when status',
      options: loanGlobalStatus,
    },
  }
}
