import type { InputType } from 'config'
import { TicketTypeTexts } from 'pages/Tickets/types'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      inputType: 'text',
      title: 'Subject',
      required: true,
    },
    type: {
      title: 'Type',
      inputType: 'select',
      options: TicketTypeTexts,
      error: '',
      required: true,
      hasDefaultOption: true,
    },
    description: {
      title: 'Description',
      inputType: 'textarea',
      error: '',
      required: true,
      span: '2',
    },

    // priority: {
    //   title: 'Priority',
    //   inputType: 'select',
    //   options: TicketPriorityText,
    //   error: '',
    //   required: true,
    //   value: TicketPriority.Normal,
    // },
    // dueDate: {
    //   title: 'Due Date',
    //   inputType: 'text',
    //   type: 'date',
    //   error: '',
    //   value: formatDateYMD(new Date().setDate(new Date().getDate() + 3)),
    // },
  }
}
