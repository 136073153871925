import { getInitialLoanFields, InputType } from 'config'
import { InputTypeOfLoans } from 'config/loan.input.fields.constants'
import { FieldOrigin, fieldOriginOptions } from 'config/loan.input.visibility.type'
import { defaultInputs as borrowerInformationInputs } from 'pages/LoanApplication/BorrowerInformation/constants'
import { declarationInputs, hmdaInputs } from 'pages/LoanApplication/DeclarationsHmda/constants'
import { defaultInputs as propertyInformationInputs } from 'pages/LoanApplication/PropertyInformation/constants'
import { Input2, ToggleButton } from 'stories/components'

export const assetLiabilityInputs = () => {
  return {
    assetInformation: {
      inputType: 'ToggleButton',
      title: 'Asset Information',
    },
    totalCredits: {
      inputType: 'ToggleButton',
      title: 'Total Credits',
    },
    liabilityInformation: {
      inputType: 'ToggleButton',
      title: 'Liability Information',
    },
  }
}

export const TrackRecordInputs = () => {
  const data: Record<string, string> = {
    purchaseDateBeforeLoanDate: `Purchase Date must be before Loan Date.`,
    addressDifferentPropertyAddr: `Address cannot be the same as Subject Property Address.`,
    purchaseDateBeforeSoldDate: `Purchase Date must be before Sold Date.`,
    soldDateBeforeLoanDate: `Sold Date must be before Loan Date.`,
  }
  let result: Record<string, InputType> = {
    sectionForNo: {
      inputType: 'Section',
      title: 'Retained As Rental? - No',
      span: 'full',
    },
  }
  Object.keys(data).forEach((key) => {
    result[key] = {
      inputType: 'ToggleButton',
      title: data[key],
    }
  })

  const additionalData: Record<string, string> = {
    soldDateWithin30monthsLoanDate: `Sold Date must be within {value} months of Loan Date.`, // 36
    purchaseDateWithin24monthsSoldDate: `Purchase Date must be within {value} months of Sold Date.`, // 24
    soldPriceLessthan75AfterRepairValue: `Sold Price, cannot be less than {value}% of After Repair Value.`, // 75
  }
  const defaultValues: Record<string, number> = {
    soldDateWithin30monthsLoanDate: 36,
    purchaseDateWithin24monthsSoldDate: 24,
    soldPriceLessthan75AfterRepairValue: 75,
  }

  const addAdditionalData = (key: string, title: string, defaultValue: number) => {
    result[key] = {
      inputType: 'custom',
      title,
      render: (value: any, onChange: Function) => {
        if (!value)
          value = {
            enabled: true,
            value: defaultValue,
          }
        return (
          <div className="flex items-center gap-2">
            <ToggleButton
              title={title.replace('{value}', value.value)}
              value={value.enabled}
              onChange={(v) => onChange({ ...value, enabled: v })}
              id={key}
            />
            <div className="w-20">
              <Input2 title="Value" value={value.value} onChange={(v) => onChange({ ...value, value: v })} />
            </div>
          </div>
        )
      },
    }
  }
  Object.keys(additionalData).forEach((key) => addAdditionalData(key, additionalData[key], defaultValues[key]))

  result['sectionForYes'] = {
    inputType: 'Section',
    title: 'Retained As Rental? - Yes',
    span: 'full',
  }
  ;['purchaseDateBeforeLoanDate', 'addressDifferentPropertyAddr'].forEach((key) => {
    result[`${key}Yes`] = {
      inputType: 'ToggleButton',
      title: data[key],
    }
  })
  result = {
    ...result,
    purchasePrice: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Purchase Price',
      required: true,
      prefix: '$',
    },
    purchaseDate: {
      inputType: 'text',
      type: 'date',
      title: 'Purchase Date',
      required: true,
    },
    renovationBudget: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Renovation Budget',
      required: true,
      prefix: '$',
    },
  }

  const additionalDataYes: Record<string, string> = {
    purchaseDateWithin24monthsExistingClosingDate: `Purchase Date must be within {value} months of existing closing date.`, // 24
    purchasePriceLessthan75TransactionPurPrice: `Purchase Price must be within {value}% of transaction Purchase Price.`, // 75
    purchaseDateFrom24monthsExistingClosingDate: `Purchase Date must be at least {value} months from existing closing date.`, // 12
  }
  const defaultValuesYes: Record<string, number> = {
    purchaseDateWithin24monthsExistingClosingDate: 24,
    purchasePriceLessthan75TransactionPurPrice: 75,
    purchaseDateFrom24monthsExistingClosingDate: 12,
  }
  Object.keys(additionalDataYes).forEach((key) => addAdditionalData(key, additionalDataYes[key], defaultValuesYes[key]))

  return result
}

export const DeclarationHMDAInputs = () => {
  // return declarationInputs
  const inputs: Record<string, InputType> = {}

  Object.keys(declarationInputs).forEach((key) => {
    const input = declarationInputs[key]
    inputs[key] = {
      ...input,
      span: 'full',
    }
  })

  inputs['group3'] = {
    inputType: 'Section',
    title: 'HMDA',
    span: 'full',
  }
  const _hmdaInputs = hmdaInputs()
  Object.keys(_hmdaInputs).forEach((key) => {
    inputs[key] = _hmdaInputs[key]
  })

  return inputs
}

export const getInputs = (): InputTypeOfLoans => {
  return {
    [FieldOrigin.LoanStructure]: getInitialLoanFields(),
    [FieldOrigin.BorrowerInformation]: borrowerInformationInputs(),
    [FieldOrigin.PropertyInformation]: propertyInformationInputs(),
    [FieldOrigin.AssetLiability]: assetLiabilityInputs(),
    [FieldOrigin.TrackRecord]: TrackRecordInputs(),
    [FieldOrigin.DeclarationsHMDA]: DeclarationHMDAInputs(),
    ['sign']: {},
    ['credit']: {},
    ['additionalMember']: {},
  } as any
}

export const visibilitySections = {
  [FieldOrigin.BorrowerInformation]: 'borrower',
  [FieldOrigin.PropertyInformation]: 'property',
  [FieldOrigin.AssetLiability]: 'asset',
  [FieldOrigin.TrackRecord]: 'track',
  [FieldOrigin.DeclarationsHMDA]: 'hmda',
  sign: 'sign',
  credit: 'credit',
  additionalMember: 'additionalMember',
}

export const extendedFieldOriginOptions = {
  ...fieldOriginOptions,
  sign: 'Acknowledgement & Sign',
  credit: 'Credit Score',
  additionalMember: 'Additional Entity Members',
}
