import { API_USER_SIGN_IN } from 'config'
import type { Dispatch } from 'react'
import Api from 'services/api'

import {
  AUTH_LOGOUT,
  AUTH_PROFILE_UPDATE,
  AUTH_TOKEN_SET,
  ON_APPLICATION_ERROR,
  SET_HEADER,
  SIGNIN_USER_REQUEST,
  USER_PERMISSIONS_SET,
} from './types'

export function signInRequest(params: any, ask2fa: Function, handleError?: Function) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: SIGNIN_USER_REQUEST })
      const queryParams = { ...params }
      const response = await Api.post(API_USER_SIGN_IN, queryParams)
      const { tfa = false, token, user } = response
      if (!tfa) dispatch({ type: AUTH_TOKEN_SET, token, user })
      else dispatch({ type: ON_APPLICATION_ERROR })
      ask2fa(!tfa, user.phone)
    } catch (error) {
      handleError && handleError()
    }
  }
}

export function setUserPermissions(role: any) {
  return { type: USER_PERMISSIONS_SET, role }
}

export function logout() {
  return { type: AUTH_LOGOUT }
}

export function authUpdateProfile(profile: any) {
  return { type: AUTH_PROFILE_UPDATE, profile }
}

export function setHeader(data: any) {
  return { type: SET_HEADER, data }
}
