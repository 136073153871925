import { setUserPermissions } from 'actions'
import { API_USER_PERMISSIONS } from 'config'
import { useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { store } from 'reducers'
import Api from 'services/api'
import { useQueryRecord } from 'utils'

import { hasPagePermissions } from './hasPagePermissions'

export const usePermissions = () => {
  const auth = useSelector((state: any) => state.auth)
  const pageParams = useParams() as any
  const pageQueries = useQueryRecord()

  const { data, isLoading, error, refetch } = useQuery(
    'permissions',
    async () => {
      return Api.get(API_USER_PERMISSIONS)
    },
    {
      enabled: auth.isAuthenticated,
      refetchOnWindowFocus: true,
      retryDelay: 45000,
      staleTime: 1000 * 60 * 5,
    },
  )

  useEffect(() => {
    data && store.dispatch(setUserPermissions(data))
  }, [data])

  const hasPermission = useCallback(
    (permissionLevel: string | Array<string>) => {
      if (permissionLevel.length == 0) return true
      if (data === undefined) return undefined
      const matched = data?.role?.permissions?.find((permissionName: string) => {
        if (Array.isArray(permissionLevel) && permissionLevel.includes(permissionName)) return true
        else if (permissionLevel === permissionName) return true
      })
      if (!matched) return false
      return true
    },
    [data],
  )

  const hasPagePermission = useCallback(
    (pageId: string[]) => {
      return hasPagePermissions(pageId, {
        ...pageParams,
        ...pageQueries,
      })
    },
    [pageParams, pageQueries],
  )

  return { data, isLoading, error, refetch, hasPermission, hasPagePermission }
}
